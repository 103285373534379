<template>
  <v-card
    :id="field.fieldType !== 'file-input' ? 'questionnaire-field' + '-' + field.id : field.id"
    :class="$vuetify.breakpoint.mobile ? 'my-4 pb-4' : 'my-10 pb-4'"
  >
    <v-card
      height="10"
      :color="isUploaded(field) ? 'green' : hasAnswer(field) ? 'green' : 'blue lighten-2'"
      :class="isUploaded(field) ? '' : hasAnswer(field) ? '' : 'mb-4'"
      flat
      class="rounded-b-0"
    />
    <v-toolbar
      v-if="isUploaded(field)"
      dense
      flat
      class="mb-2"
      color="lightBackground"
    >
      <v-toolbar-title
        class="d-flex align-center rounded primary--text"
        style="font-size: 20px;"
      >
        <v-icon
          class="mr-2"
          color="primary"
        >
          mdi-file-check-outline
        </v-icon>
        {{ $t('documents|document_is_already_uploaded') }}
      </v-toolbar-title>
      <v-spacer />
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon
            icon
            color="primary"
            v-on="on"
            @click="$emit('preview')"
          >
            mdi-eye
          </v-icon>
        </template>
        <span>{{ $t('actions|preview') }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            class="ml-2"
            color="primary"
            v-on="on"
            @click="deleteField"
          >
            <v-icon>
              {{ icons.trash }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('actions|delete_field') }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-toolbar
      v-if="hasAnswer(field)"
      dense
      flat
      class="mb-2"
      color="lightBackground"
    >
      <v-toolbar-title
        class="d-flex align-center rounded primary--text"
        style="font-size: 20px;"
      >
        <v-icon
          class="mr-2"
          color="primary"
        >
          {{ icons.message }}
        </v-icon>
        {{ $t('questionnaire|client_has_answered_this_question') }}
      </v-toolbar-title>
      <v-spacer />
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon
            icon
            color="primary"
            v-on="on"
            @click="$emit('preview')"
          >
            mdi-eye
          </v-icon>
        </template>
        <span>{{ $t('actions|preview') }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            class="ml-2"
            color="primary"
            v-on="on"
            @click="deleteField"
          >
            <v-icon>
              {{ icons.trash }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('actions|delete_field') }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-row
      justify="end"
      class="mb-2 mr-0 pt-1"
      :class="calculateClass(field)"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="primary"
            dark
            class="handle-icon"
            v-bind="attrs"
            v-on="on"
          >
            {{ icons.drag }}
          </v-icon>
        </template>
        <span>{{ $t('actions|drag') }}</span>
      </v-tooltip>
      <!-- <v-btn
        v-if="!field.customHashtag"
        color="white"
        x-small
        class="pa-3"
        @click="openFieldTypeDialog"
      >
        <span
          class="text-center"
          style="font-size: 6px"
        >
          switch to<br><span style="font-size: 9px">Custom Question</span>
        </span>
      </v-btn>
      <v-btn
        v-if="field.customHashtag"
        color="white"
        small
        @click="toggleCustomHashtag"
      >
        <span
          class="text-center"
          style="font-size: 6px"
        >
          switch to<br><span style="font-size: 9px">lavvira Question</span>
        </span>
      </v-btn> -->
      <v-menu
        v-if="!field.customHashtag"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            small
            class="mr-2 pa-3"
            v-bind="attrs"
            v-on="on"
          >
            <span
              class="text-center"
              style="font-size: 8px"
              v-bind="attrs"
              v-on="on"
            >
              {{ $t('actions|change').toUpperCase() }}<br><span style="font-size:11px">{{ $t('questionnaire|question_type').toUpperCase() }}</span>
            </span>
            <v-icon
              class="mr-n2"
            >
              mdi-menu-down
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(fieldType, index) in fieldTypes"
            :key="index"
            @click="changeField(fieldType)"
          >
            <v-list-item-title>{{ getFieldType(fieldType) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- <v-menu
        v-if="field.customHashtag"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="white"
            x-small
            class="ml-1"
            v-bind="attrs"
            v-on="on"
          >
            <span
              class="text-center"
              style="font-size: 6px"
            >
              change<br><span style="font-size: 9px">Question Type</span>
            </span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(fieldType, index) in fieldTypes"
            :key="index"
            @click="changeFieldTypeIfCustom(fieldType)"
          >
            <v-list-item-title>{{ getFieldType(fieldType) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <!-- <boolean-input v-model="required" /> -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="!isUploaded(field) && !hasAnswer(field)"
            icon
            small
            v-bind="attrs"
            class="mr-1 delete-btn"
            v-on="on"
            @click="deleteField"
          >
            <v-icon>
              {{ icons.trash }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('actions|delete_field') }}</span>
      </v-tooltip>
    </v-row>
    <v-row
      justify="start"
      class="mb-1 mt-n1 ml-2"
    >
      <v-chip
        v-if="field.customLabel"
        class="pa-2 mx-2"
        x-small
        label
        color="grey"
        outlined
      >
        {{ $t('fields|custom_label') }}: [{{ field.customLabel }}]
      </v-chip>
      <v-chip
        class="pa-2 mx-2"
        x-small
        label
        color="grey"
        outlined
      >
        <v-icon
          v-if="getFieldType(field.fieldType) === this.$t('fields|text-input')"
          left
          small
        >
          {{ icons.textQuestion }}
        </v-icon>
        <v-icon
          v-if="getFieldType(field.fieldType) === this.$t('fields|date-input')"
          left
          small
        >
          {{ icons.dateQuestion }}
        </v-icon>
        <v-icon
          v-if="getFieldType(field.fieldType) === this.$t('fields|currency-input')"
          left
          small
        >
          {{ icons.sumQuestion }}
        </v-icon>
        <v-icon
          v-if="getFieldType(field.fieldType) === this.$t('fields|file-input')"
          left
          color="grey"
          small
        >
          {{ icons.documentQuestion }}
        </v-icon>
        {{ getFieldType(field.fieldType) }}
      </v-chip>
      <v-chip
        v-if="findFieldInTemplate()"
        class="pa-2 mx-2"
        x-small
        label
        color="grey"
        outlined
      >
        <v-icon
          left
          small
        >
          {{ icons.template }}
        </v-icon>
        {{ $t('mixed|in_use_in_template') }}
      </v-chip>
      <v-chip
        v-if="!findFieldInTemplate()"
        class="pa-2 mx-2"
        x-small
        label
        color="grey"
        outlined
      >
        <v-icon
          left
          small
        >
          {{ icons.template }}
        </v-icon>
        {{ $t('mixed|not_in_use_in_template') }}
      </v-chip>
    </v-row>
    <v-card-text
      :class="calculateClass(field)"
    >
      <v-autocomplete
        v-if="!field.customHashtag && field.fieldType !== 'currency-input'"
        v-model="field.name"
        :value="field.name"
        :items="fields"
        dense
        item-text="name"
        return-object
        :label="$t('questionnaire|select_question')"
        outlined
      >
        <template v-slot:item="{ item, attrs, on }">
          <v-list-item
            ripple
            v-bind="attrs"
            :disabled="item.disabled"
            v-on="on"
            @click="onPredefinedFieldSelect(item)"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-row
                  no-gutters
                  align="center"
                >
                  <span>{{ item.name }}</span>
                  <v-spacer />
                  <span v-if="item.disabled">
                    {{ $t('common|in_use') }}
                  </span>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
      <v-text-field
        v-if="field.fieldType === 'currency-input' && !field.customHashtag"
        v-model="field.name"
        hide-details
        dense
        :label="$t('common|amount')"
        outlined
        class="mb-4"
      />

      <!-- with save icon option custom hashtag field -->
      <!-- v-if="field.customHashtag"
        v-model="field.name"
        hide-details
        dense
        label="Custom Question"
        append-outer-icon="mdi-information-variant"
        append-icon="mdi-content-save"
        outlined
        class="mb-4"
        @click:append="onPredefinedFieldSelect(field)"
        @click:append-outer="openDialog(field)" -->
      <v-text-field
        v-if="field.customHashtag"
        v-model="field.name"
        hide-details
        dense
        :label="$t('questionnaire|custom_question')"
        outlined
        class="mb-4"
        @blur="onPredefinedFieldSelect(field)"
      />
      <v-textarea
        v-model="field.hint"
        hide-details
        rows="3"
        row-height="25"
        :label="$t('common|hint')"
        outlined
        @blur="onClickOutside"
      />
    </v-card-text>
    <!-- <v-dialog
      v-model="fieldTypeDialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          Choose question type
        </v-card-title>
        <v-list>
          <v-list-item
            v-for="(fieldType, index) in fieldTypes"
            :key="index"
            @click="toggleCustomHashtag(fieldType)"
          >
            <v-list-item-title>{{ getFieldType(fieldType) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog> -->
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import {
  mdiTrashCanOutline,
  mdiTextBoxOutline,
  mdiCommentTextOutline,
  mdiCalendarQuestion,
  mdiMessageOutline,
  mdiNumeric,
  mdiFileHidden,
  mdiDrag
} from '@mdi/js'
import fieldTypes from '@/enums/fieldTypes'

export default {
  props: {
    field: {
      type: Object,
      default: () => ({})
    },
    excludeFields: {
      type: Array,
      default: () => ([])
    },
    questionairreFields: {
      type: Array,
      default: () => ([])
    },
    activeLanguage: {
      type: String,
      default: 'en'
    }
  },
  data: () => ({
    icons: {
      trash: mdiTrashCanOutline,
      template: mdiTextBoxOutline,
      textQuestion: mdiCommentTextOutline,
      dateQuestion: mdiCalendarQuestion,
      sumQuestion: mdiNumeric,
      documentQuestion: mdiFileHidden,
      drag: mdiDrag,
      message: mdiMessageOutline
    },
    fieldTypes,
    fieldTypeDialog: false
  }),
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
      actualLanguage: state => state.account.actualLanguage
    }),
    fields () {
      if (!this.settings || !this.settings.fields) return []
      const arr = Object.keys(this.settings.fields).reduce((acc, key) => {
        const _key = key.toLowerCase()
        const disabled = this.excludeFields.some(f => f.key === _key)
        const field = this.settings.fields[key]
        if (field.type === this.field.fieldType) {
          if (this.settings.fields[key].label[this.actualLanguage]) {
            acc.push({
              id: this.field.id,
              name: this.settings.fields[key].label[this.actualLanguage],
              fieldType: this.settings.fields[key].type,
              fieldKey: key,
              disabled
            })
          }
        }
        acc.push(this.field)
        return acc
      }, [])
      return arr
    }
  },
  watch: {
    field (value) {
      if (value.customHashtag && value.name === ' ') {
        value.name = 'Add text...'
      }
    }
  },
  methods: {
    onClickOutside () {
      this.$emit('save-questionnaire')
    },
    isUploaded (field) {
      if (field && field.fieldType === 'file-input' && field.value && field.value.length) return true
      else return false
    },
    hasAnswer (field) {
      if (field && field.fieldType !== 'file-input' && field.value && field.value.length) return true
      else return false
    },
    calculateClass (field) {
      if (field && field.fieldType === 'file-input' && field.value && field.value.length && field.isUploaded) {
        return 'disabled-card'
      } else if (field && field.fieldType !== 'file-input' && field.value) {
        return 'disabled-card'
      }
    },
    calculateColor (field) {
      if (field && field.fieldType === 'file-input' && field.value && field.value.length) {
        return 'black'
      }
    },
    calculateCardColor (field) {
      if (field && field.fieldType === 'file-input' && field.value && field.value.length) {
        return 'success'
      }
    },
    // toggleCustomHashtag (fieldType) {
    //   // this.field.customHashtag = !this.field.customHashtag
    //   if (this.field.customHashtag) {
    //     const foundField = this.excludeFields.find(f => f.id === this.field.id) ||
    //     this.questionairreFields.find(f => f.id === this.field.id)
    //     if (foundField) {
    //       this.field.name = foundField.name
    //       this.field.hint = foundField.hint
    //       this.field.key = foundField.key
    //       this.field.customHashtag = !this.field.customHashtag
    //     } else {
    //       this.field.name = ' '
    //       this.field.hint = null
    //       this.field.key = ' '
    //       this.field.customHashtag = !this.field.customHashtag
    //     }
    //   } else {
    //     this.field.customHashtag = !this.field.customHashtag
    //     this.field.name = ' '
    //     this.field.hint = null
    //     this.field.key = ' '
    //     this.field.fieldType = fieldType
    //     this.fieldTypeDialog = false
    //   }
    //   this.$emit('fieldChanged', this.field)
    // },
    openFieldTypeDialog () {
      this.fieldTypeDialog = true
    },
    onPredefinedFieldSelect (field) {
      // const fieldKey = field.name.toLowerCase().split(' ').join('_')
      this.field.fieldKey = field.fieldKey
      this.field.name = field.name
      this.field.fieldType = field.fieldType
      this.field.customHashtag = field.customHashtag || field.name === 'Custom'
      this.field.hint = field.hint ? field.hint : null
      this.$emit('fieldChanged', this.field)
    },
    async changeField (fieldType) {
      if (fieldType === 'file-input') {
        const res = await this.$dialog.confirm({
          title: this.$t('common|warning'),
          text: this.$t('expressions|changing_the_question_type_to_request_document')
        })
        if (!res) return
      }
      const foundField = this.excludeFields.find(f => f.id === this.field.id) ||
      this.questionairreFields.find(f => f.id === this.field.id)
      if (foundField && foundField.fieldType === fieldType) {
        this.field.fieldType = fieldType
        this.field.key = foundField.key
        this.field.name = foundField.name
        this.field.hint = foundField.hint
      } else {
        this.field.customHashtag = false
        this.field.fieldType = fieldType
        this.field.key = null
        this.field.name = ''
        this.field.hint = null
      }
      this.$emit('fieldChanged', this.field)
    },
    changeFieldTypeIfCustom (fieldType) {
      this.field.customHashtag = true
      this.field.fieldType = fieldType
      this.field.key = this.field.key
      this.field.name = this.field.name
      this.field.hint = this.field.hint
    },
    deleteField () {
      this.$emit('deleteField', this.field)
    },
    getFieldType (fieldType) {
      switch (fieldType) {
      case 'text-input':
        return this.$t('fields|text-input')
      case 'date-input':
        return this.$t('fields|date-input')
      case 'currency-input':
        return this.$t('fields|currency-input')
      case 'file-input':
        return this.$t('fields|file-input')
      }
    },
    findFieldInTemplate () {
      let foundIndex = this.excludeFields.findIndex(f => f.id === this.field.id)
      if (foundIndex === -1) {
        return false
      }
      if (foundIndex !== -1) {
        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .handle-icon {
    position: absolute;
    left: 0px;
    cursor: move;
  }
  .disabled-card {
    pointer-events: none;
    opacity: 0.5;
  }
  .delete-btn {
    pointer-events: auto;
    opacity: 1;
  }
</style>
